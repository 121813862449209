import { useTranslation } from "react-i18next";
import "../../../assets/scss/google-drive-card-style.scss";
import { GoogleDriveCardInterface } from "../../utility/interface/google-drive-card";

import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Button } from "antd";
import { downloadFromGoogleDrive } from "../../server/GoogleDrive";

export const GoogleDriveCard = (props: GoogleDriveCardInterface) => {
  const { t } = useTranslation();
  const { id, name, mimeType, modifiedTime, createdTime, userState } = props;
  let treatedState = userState.split("|user_id")[0];
  const navigate = useNavigate();

  return (
    <>
      <div key={id} className="google-drive-card">
        <h3>{name}</h3>
        <span>Modified Time: {moment(modifiedTime).format("YYYY-MM-DD")}</span>
        <span>Created Time: {moment(createdTime).format("YYYY-MM-DD")}</span>
        {mimeType}

        <Button
          type="primary"
          onClick={() =>
            downloadFromGoogleDrive(treatedState, {
              file_id: id,
              destination_path: "/workspace",
            })
          }
        >
          Download
        </Button>
      </div>
    </>
  );
};
