import { useTranslation } from "react-i18next";
import "../../../assets/scss/template-card-style.scss";
import { updateTemplateSelectionInfo } from "../../features/selection/selectionSlice";
import { useAppDispatch } from "../../store/hooks";

import { TemplateCardInterface } from "../../utility/interface/template-card";

import { Button } from "antd";
import { useNavigate } from "react-router-dom";

export const TemplateCard = (props: TemplateCardInterface) => {
  const { t } = useTranslation();
  const {
    template_id,
    template_name,
    template_description,
    icon_url,
    start_script,
    fakeData,
  } = props;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const selectTemplate = () => {
    dispatch(updateTemplateSelectionInfo(template_id));
    navigate("/rentSummary");
  };

  const isSVGFormatImage = (url: string) => {
    return url.endsWith(".svg");
  };

  return (
    <>
      <div className={fakeData ? "template-card fake" : "template-card"}>
        <div className="template-card-wrapper">
          <div className="template-card-image">
            <img
              src={`${icon_url}`}
              style={isSVGFormatImage(icon_url) ? { width: "40px" } : {}}
              alt="logo"
            />
          </div>
          <div className="template-card-specs">
            <h3>{template_name}</h3>
            <span>{template_description}</span>
          </div>
        </div>
        <Button
          disabled={fakeData}
          type="primary"
          onClick={() => selectTemplate()}
        >
          {t("Select")}
        </Button>
      </div>
    </>
  );
};
