import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { InstanceInterface } from "../../utility/interface/api/instances/get-instance-res";

const initialState: InstanceInterface = {
  instance_id: 0,
  machine_id: 0,
  price: 0,
  gpu_count: 0,
  time_consumed: 0,
  service_url: "",
  ssh_url: "",
  user_id: 0,
};

export const instanceSlice = createSlice({
  name: "instance",
  initialState,
  reducers: {
    chooseInstance: (state, action: PayloadAction<InstanceInterface>) => {
      return action.payload;
    },
    removeInstance: (state) => {
      return initialState;
    },
  },
});

// Action creators are generated for each case reducer function
export const { chooseInstance, removeInstance } = instanceSlice.actions;

export default instanceSlice.reducer;
